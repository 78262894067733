import React, { useState } from "react";
import "../../css/about/about-mobile-section.css";
// import Navbar from "../Navbar";
import Navbar from "../../components/Navbar";
import { AnimatePresence } from "framer-motion";
// import MenuMobileContainer from "../MenuMobileContainer";
import MenuMobileContainer from "../../components/MenuMobileContainer";
// import AboutMobileLanding from "./AboutMobileLanding";
import AboutMobileLanding from "../../components/about mobile components/AboutMobileLanding";
import AboutMobileHelp from "../../components/about mobile components/AboutMobileHelp";
import AboutMobilePortofolio from "../../components/about mobile components/AboutMobilePortofolio";
import AboutMobileMeetOverview from "../../components/about mobile components/AboutMobileMeetOverview";
import AboutMobileFooter from "../../components/about mobile components/AboutMobileFooter";
import { Helmet } from "react-helmet";
import PageTransition from "../../components/PageTransition";

const MobileThoughtProcessPage = ({ setIsHomeLink }) => {
  const [menuRepoBool, setMenuRepoBool] = useState(false);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="about-mobile-section">
      <Helmet>
        <title>
          Blueprint - About Us | Your Partner in Crafting Innovative Digital
          Experiences
        </title>
      </Helmet>
      <img
        src="/svgs/home_background_mobile.svg"
        alt=""
        className="mobile-bg"
      />
      <Navbar setMenuRepoBool={setMenuRepoBool} setIsHomeLink={setIsHomeLink} />
      <AnimatePresence mode="wait">
        {menuRepoBool && (
          <MenuMobileContainer
            setIsHomeLink={setIsHomeLink}
            setMenuRepoBool={setMenuRepoBool}
          />
        )}
      </AnimatePresence>

      <div className="about-mobile-container">
        <div className="thought-process-landing">
          <div className="title">
            My Design <br /> Thought <br /> Process
          </div>
          <div className="content">
            Design is more than aesthetics—it’s about problem-solving, strategy,
            and user empathy. This page outlines my thought process when
            tackling a project, giving you insight into how I approach design
            challenges.
            <br /> <br /> Unlike many project showcases that focus only on the
            final product, I believe in transparency, sharing the journey from
            ideation to execution.
          </div>
        </div>
        <div className="thought-container-section">
          <div className="thought-container">
            <div className="left">
              <img src="/svgs/Filling Survey_mb.svg" alt="" />
            </div>
            <div className="right">
              <div className="title">
                1. <br /> Understanding the <br /> Problem
              </div>
              <div className="content">
                Every successful design begins with understanding the core
                problem. I conduct interviews and surveys to gather insights on
                user needs, company goals, and potential pain points.
                <br /> <br /> This helps shape problem statements, user stories,
                and journeys to ensure the solution is truly impactful.
              </div>
            </div>
          </div>
          <div className="thought-container dark">
            <div className="left">
              <img src="/svgs/Group_mb.svg" alt="" />
            </div>
            <div className="right">
              <div className="title">
                2. <br /> Competitive Analysis
              </div>
              <div className="content">
                To create a standout product, I analyze competitors—identifying
                gaps, strengths, and opportunities. <br /> <br /> This helps
                define a unique value proposition and ensures the project
                differentiates itself in a meaningful way.
              </div>
            </div>
          </div>
          <div className="thought-container">
            <div className="left">
              <img src="/svgs/Financial Literacy_mb.svg" alt="" />
            </div>
            <div className="right">
              <div className="title">
                3. <br /> Evaluating Project <br /> Profitability
              </div>
              <div className="content">
                Prioritization is key. I assess how each feature contributes to
                business goals and user needs, ensuring that time and resources
                are allocated efficiently for maximum impact.
              </div>
            </div>
          </div>
          <div className="thought-container dark">
            <div className="left">
              <img
                src="/images/20 UX UI App Design Instagram Accounts You Should Follow_mb.png"
                alt=""
              />
            </div>
            <div className="right">
              <div className="title">
                4. <br /> Conceptualization & <br />
                Early Sketches
              </div>
              <div className="content">
                Ideas start with rough sketches and low-fidelity prototypes.
                This stage allows rapid iteration, focusing on layout,
                structure, and functionality before diving into detailed
                designs.
              </div>
            </div>
          </div>
          <div className="thought-container">
            <div className="left">
              <img
                src="/images/Information Architecture Familio Mobile App UX_UI_mb.png"
                alt=""
              />
            </div>
            <div className="right">
              <div className="title">
                5. <br /> Information Architecture
              </div>
              <div className="content">
                Structuring content effectively is crucial for usability. I
                create sitemaps, navigation flows, and content hierarchies to
                ensure users can find information effortlessly. A
                well-thought-out information architecture improves user
                experience and interaction efficiency.
              </div>
            </div>
          </div>
          <div className="thought-container dark">
            <div className="left">
              <img
                src="/images/MEDICO — Doctor-Patient Management System _UI_UX Design - Anna Dvornikova_mb.png"
                alt=""
              />
            </div>
            <div className="right">
              <div className="title">
                6. <br /> Building the Design <br />
                System
              </div>
              <div className="content">
                A solid design system lays the foundation for consistency and
                scalability. This includes color palettes, typography, component
                libraries, and interaction guidelines to maintain visual
                harmony.
              </div>
            </div>
          </div>
          <div className="thought-container">
            <div className="left">
              <img src="/images/fidelity_mb.png" alt="" />
            </div>
            <div className="right">
              <div className="title">
                7. <br /> High-Fidelity Design & <br /> Interactive Mockups
              </div>
              <div className="content">
                Once the foundation is set, I transform concepts into polished
                high-fidelity designs with rich details and interactions. These
                mockups bring the vision to life, making it easier for
                stakeholders and developers to visualize the final product.
              </div>
            </div>
          </div>
          <div className="thought-container dark">
            <div className="left">
              <img src="/images/Group 1_mb.png" alt="" />
            </div>
            <div className="right">
              <div className="title">
                8. <br /> Prototyping for Better <br /> Communication
              </div>
              <div className="content">
                Interactive prototypes bridge the gap between design and
                development. They help stakeholders experience the product flow
                and ensure smooth handoffs to developers.
              </div>
            </div>
          </div>
          <div className="thought-container">
            <div className="left">
              <img src="/images/Listening To Feedback_mb.png" alt="" />
            </div>
            <div className="right">
              <div className="title">
                9. <br /> Testing & Refinement <br /> (My Favorite Part)
              </div>
              <div className="content">
                Usability testing is crucial. I analyze user feedback, identify
                pain points, and refine designs accordingly. This iterative
                process ensures that the final product is intuitive and
                user-friendly.
              </div>
            </div>
          </div>
          <div className="thought-container dark">
            <div className="left">
              <img src="/images/Business Deal_mb.png" alt="" />
            </div>
            <div className="right">
              <div className="title">
                10. <br /> Documentation & <br /> Handover
              </div>
              <div className="content">
                A well-documented design makes implementation seamless. I
                provide detailed specs, design rationale, and annotations to
                ensure a smooth transition from design to development.
              </div>
            </div>
          </div>
        </div>
        <div className="thought-container-tools">
          <div className="tools-container">
            <div className="title">Tools I Use</div>
            <div className="tools">
              <img
                src="/images/Figma_ The Collaborative Interface Design Tool.png"
                alt=""
              />
              <img src="/images/Adobe XD Logo.png" alt="" />
            </div>
          </div>
          <div className="strength-section">
            <div className="title">Strength Evaluation</div>
            <div className="strengths">
              <div className="strength">
                <p>User Experience (UX)</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "90%" }}></div>
                  </div>
                  <div className="progress">90%</div>
                </div>
              </div>
              <div className="strength">
                <p>User Interface (UI) - Mobile & Web</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "95%" }}></div>
                  </div>
                  <div className="progress">95%</div>
                </div>
              </div>
              <div className="strength">
                <p>Graphic Design</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "64%" }}></div>
                  </div>
                  <div className="progress">64%</div>
                </div>
              </div>
              <div className="strength">
                <p>Design Systems</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Information Architecture</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "80%" }}></div>
                  </div>
                  <div className="progress">80%</div>
                </div>
              </div>
              <div className="strength">
                <p>Prototyping & Microinteractions</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Usability Testing & Research</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Accessibility (A11y) & Inclusive Design</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "80%" }}></div>
                  </div>
                  <div className="progress">80%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AboutMobilePortofolio />
        <AboutMobileMeetOverview /> */}
        <AboutMobileFooter setIsHomeLink={setIsHomeLink} />
        <div className="scroll-bottom" onClick={scrollToBottom}>
          <img src="/svgs/angle-down.svg" alt="" />
        </div>
      </div>
      <PageTransition pageTitle={"Thought Process"} />
    </div>
  );
};

export default MobileThoughtProcessPage;
