import React, { useState } from "react";
import "../../css/about/about-mobile-section.css";
// import Navbar from "../Navbar";
import Navbar from "../../components/Navbar";
import { AnimatePresence } from "framer-motion";
// import MenuMobileContainer from "../MenuMobileContainer";
import MenuMobileContainer from "../../components/MenuMobileContainer";
// import AboutMobileLanding from "./AboutMobileLanding";
import AboutMobileLanding from "../../components/about mobile components/AboutMobileLanding";
import AboutMobileHelp from "../../components/about mobile components/AboutMobileHelp";
import AboutMobilePortofolio from "../../components/about mobile components/AboutMobilePortofolio";
import AboutMobileMeetOverview from "../../components/about mobile components/AboutMobileMeetOverview";
import AboutMobileFooter from "../../components/about mobile components/AboutMobileFooter";
import { Helmet } from "react-helmet";

const AboutBamiseMobileSection = ({ setIsHomeLink, individualDetails }) => {
  const [menuRepoBool, setMenuRepoBool] = useState(false);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="about-mobile-section">
      <Helmet>
        <title>
          Blueprint - About Us | Your Partner in Crafting Innovative Digital
          Experiences
        </title>
      </Helmet>
      <img src="/svgs/home_mob_bg.svg" alt="" className="mobile-bg" />
      <Navbar setMenuRepoBool={setMenuRepoBool} setIsHomeLink={setIsHomeLink} />
      <AnimatePresence mode="wait">
        {menuRepoBool && (
          <MenuMobileContainer
            setIsHomeLink={setIsHomeLink}
            setMenuRepoBool={setMenuRepoBool}
          />
        )}
      </AnimatePresence>

      <div className="about-mobile-container">
        <AboutMobileLanding individualDetails={individualDetails} />
        <AboutMobileHelp />
        <AboutMobilePortofolio />
        <AboutMobileMeetOverview />
        <AboutMobileFooter setIsHomeLink={setIsHomeLink} />
        <div className="scroll-bottom" onClick={scrollToBottom}>
          <img src="/svgs/angle-down.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutBamiseMobileSection;
