import React from "react";
import "../../css/about/footer.css";
// import BtnMagnetic from "../ButtonMagnetic";
// import ProceedBtn from "../ProceedBtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BtnMagnetic from "../../components/ButtonMagnetic";
import ProceedBtn from "../../components/ProceedBtn";
import GrabMagnetic from "../../components/GrabMagnetic";
// import GrabMagnetic from "../GrabMagnetic";

const FooterBamise = ({ setIsHomeLink }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`footer  ${
        location.pathname.split("/").includes("thought-process") &&
        "thought-process-footer"
      }`}
    >
      <div
        className={`ctn ${
          location.pathname.split("/").includes("thought-process") &&
          "thought-process"
        }`}
      >
        <h3>
          Let's Build <br />
          Something <br />
          Amazing
        </h3>
        <p>
          I don’t just design products—I create experiences <br /> that engage
          and inspire. Every project is an <br />
          opportunity to transform ideas into functional, <br />
          user-centric solutions that stand out.
          <br />
          <br /> Ready to bring your vision to life? Let’s create <br />
          something exceptional together.
        </p>
        <div className="btn-ctrn">
          <BtnMagnetic>
            <ProceedBtn
              text="Get started with Bamise"
              changeImg={true}
              handleNext={() => navigate("/bamise/contact-bamise")}
            />
          </BtnMagnetic>
        </div>
      </div>
      <div
        className={`footer-btm bamise  ${
          location.pathname.split("/").includes("thought-process") &&
          "thought-process"
        }`}
      >
        <Link to="/bamise" onClick={() => setIsHomeLink(true)} className="logo">
          <div className="scope scope-bamise">
            <p>Bamise Oluwatimileyin</p>
          </div>
          <img src="/images/bamise_profile.png" alt="" />
        </Link>
        <div className="links">
          <div className="a">
            <GrabMagnetic>
              <Link to="/bamise/projects">Projects</Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/bamise">About me</Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/bamise/contact-bamise">Contact me</Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/bamise/thought-process">
                Thought
                <br /> Process
              </Link>
            </GrabMagnetic>
          </div>
        </div>
        <div className="social-links">
          <p>Follow Us</p>
          <div className="socials">
            <Link
              to="https://www.linkedin.com/in/yusuf-oluwatimileyin"
              className="social"
            >
              <GrabMagnetic>
                <img src="/svgs/linkedin_last_black.svg" alt="" />
              </GrabMagnetic>
            </Link>
            <div className="social">
              <GrabMagnetic>
                <img src="/svgs/instagram.svg" alt="" />
              </GrabMagnetic>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBamise;
