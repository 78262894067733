import React from "react";
import RoundedButton from "../RoundedButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../css/work/next-case.css";

const NextCase = ({
  setIsCase,
  title,
  location,
  details,
  src,
  link,
  bamiseLink,
}) => {
  const navigate = useNavigate();
  const locationUse = useLocation();

  return (
    <div className="next-case">
      <div className="left">
        <div className="stretch">
          <RoundedButton
            // backgroundColor="#37bb9ed3"
            height={200}
            width={200}
            handleNext={() => {
              navigate("/projects");
            }}
          >
            <p>More projects</p>
          </RoundedButton>
        </div>
      </div>
      <div
        className="right"
        onMouseEnter={() => setIsCase(true)}
        onMouseLeave={() => setIsCase(false)}
      >
        <div className="scope">
          <p>Next case</p>
        </div>
        <h1>{title}</h1>
        <Link
          to={
            locationUse.pathname.split("/").includes("bamise")
              ? bamiseLink
              : link
          }
          className="case"
        >
          <div className="left-case">
            <h2>{title}</h2>
            <h3>{location}</h3>
            <p>{details}</p>
          </div>
          <div className="right-case">
            <img src={src} alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NextCase;
