import React from "react";
import { Link, useLocation } from "react-router-dom";

const AboutMobileMeetOverview = () => {
  const location = useLocation();

  return (
    <div className="about-meet-overview">
      {/* <div className="overview">
        <h1>OVERVIEW</h1>
        <p>
          At Blueprint, we are dedicated to transforming innovative ideas into
          user-centric digital solutions. Founded with a passion for speed,
          innovation, and precision, our company thrives on delivering
          exceptional design and development services to startups, SMEs, and
          corporations. Our journey began with the mission to create impactful
          user experiences through cutting-edge design and development
          practices.
          <br /> <br /> What sets us apart? It's our commitment to putting the
          user first. We understand the importance of aligning design with
          function, crafting solutions that are not only beautiful but intuitive
          and efficient. Our team of experts prioritizes collaboration,
          transparency, and delivering top-quality work that meets tight
          deadlines without compromising excellence.
        </p>
      </div> */}
      <div className="meet-team">
        <h3>Meet the team</h3>
        <h1>
          Innovative.
          <br />
          User-Centric.
          <br />
          Speed.
        </h1>
        {!location.pathname.split("/").includes("bamise") && (
          <Link
            to="https://www.linkedin.com/in/otunbamarcus/"
            target="_blank"
            className="team first"
          >
            <img
              className="head-picture"
              src="/images/marcus_head_transparent.png"
              alt=""
            />
            <div className="scope">
              <div className="career">Developer</div>
            </div>
            <div className="bottom-content">
              <div className="left">
                <h4>Marcus Otunba</h4>
                <p>
                  My goal is to build fast, scalable solutions that enhance user
                  experiences across all platforms.
                </p>
              </div>
              <div className="right">
                <div className="icon">
                  <img src="/svgs/linked_work.svg" alt="" />
                </div>
              </div>
            </div>
          </Link>
        )}
        <Link
          to="https://www.linkedin.com/in/yusuf-oluwatimileyin/"
          target="_blank"
          className="team second"
        >
          <img
            className="head-picture"
            src="/images/timi_head_transparent.png"
            alt=""
          />
          <div className="scope">
            <div className="career">Designer</div>
          </div>
          <div className="bottom-content">
            <div className="left">
              <h4>Bamise Timi</h4>
              <p>
                At Blueprint, I merge creativity with functionality to ensure
                every design is as practical as it is beautiful.
              </p>
            </div>
            <div className="right">
              <div className="icon">
                <img src="/svgs/linked_work.svg" alt="" />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AboutMobileMeetOverview;
