import React from "react";
import "../../css/about/about-mobile-footer.css";
import { Link, useLocation } from "react-router-dom";

const AboutMobileFooter = ({ setIsHomeLink }) => {
  const location = useLocation();

  return (
    <div className="about-mobile-footer">
      {location.pathname.split("/").includes("bamise") ? (
        <div
          className={`content ${
            location.pathname.split("/").includes("thought-process") &&
            "thought-process"
          }`}
        >
          <h2>Let's Build Something Amazing</h2>
          <p>
            I don’t just design products—I create experiences that engage and
            inspire. Every project is an opportunity to transform ideas into
            functional, user-centric solutions that stand out.
            <br />
            <br />
            Ready to bring your vision to life? Let’s create something
            exceptional together.
          </p>
          <Link
            to="/bamise/contact-bamise"
            onClick={() => setIsHomeLink(true)}
            className="get-started"
          >
            Get <br /> Started
          </Link>
        </div>
      ) : (
        <div className="content">
          <p>
            Your Vision, Our Blueprint for Success.
            <br />
            <br />
            At Blueprint, we don’t just design products; we craft experiences
            that resonate. Every project we undertake is an opportunity to turn
            your unique vision into a reality that stands out. <br /> <br />
            Ready to see your ideas come to life? Let’s build your blueprint for
            success together.
          </p>
          <Link
            to="/"
            onClick={() => setIsHomeLink(true)}
            className="get-started"
          >
            Get <br /> Started
          </Link>
        </div>
      )}
      <div
        className={`mobile-footer  ${
          location.pathname.split("/").includes("thought-process") &&
          "thought-process"
        }`}
      >
        {location.pathname.split("/").includes("bamise") ? (
          <Link
            to="/bamise"
            onClick={() => setIsHomeLink(true)}
            className="mobile-logo bamise"
          >
            <img
              style={{ filter: "none" }}
              src="/images/bamise_footer_profile.png"
              alt=""
            />
            <h2>
              Bamise <br /> Oluwatimileyin
            </h2>
          </Link>
        ) : (
          <Link
            to="/"
            onClick={() => setIsHomeLink(true)}
            className="mobile-logo"
          >
            <img src="/svgs/blueprint_tiny.svg" alt="" />
            <h2>BluePrint</h2>
          </Link>
        )}
        {location.pathname.split("/").includes("bamise") ? (
          <div className="contact-details">
            <p>CONTACT DETAILS</p>
            <a href="mailto:Yusuf.oluwatimileyin94@gmail.com">
              Yusuf.oluwatimileyin94@gmail.com
            </a>
            <a href="tel:+2349060100257">+234 906 010 0257</a>
          </div>
        ) : (
          <div className="contact-details">
            <p>CONTACT DETAILS</p>
            <a href="mailto:help@blueprint.com">help@blueprint.com</a>
            <a href="tel:+2349060100257">+234 906 010 0257</a>
            <a href="tel:+2348035915522">+234 803 591 5522</a>
          </div>
        )}
        <div className="buisness-details">
          <p>BUSINESS DETAILS</p>
          <div className="address">
            Location: Ibadan, Oyo State, Nigeria & Near you
          </div>
        </div>
        {location.pathname.split("/").includes("bamise") ? (
          <div className="mobile-links">
            <Link to="/bamise/projects">Projects</Link>
            <Link to="/bamise">About me</Link>
            <Link to="/bamise/contact-bamise">Contact me</Link>
          </div>
        ) : (
          <div className="mobile-links">
            <Link to="/projects">Projects</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/" onClick={() => setIsHomeLink(true)}>
              Get Started
            </Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        )}
        <div className="socials">
          <p>Follow Us</p>
          <div className="social-icons">
            <img src="/svgs/linkedin_last_black.svg" alt="" />
            <img src="/svgs/instagram.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMobileFooter;
