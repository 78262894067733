import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../css/about/about.css";
import {
  useScroll,
  useTransform,
  motion,
  AnimatePresence,
} from "framer-motion";
import StickyCursor from "../../components/StickyCursor";
import { debounce } from "lodash";
import Footer from "../../components/about components/Footer";
import PageTransition from "../../components/PageTransition";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import MenuContainer from "../../components/MenuContainer";
import BurgerMenu from "../../components/BurgerMenu";
import StickyCase from "../../components/work project components/StickyCase";
import WorkMobileProjectPage from "../../pages/WorkMobileProjectPage";
import ThoughtProcess from "../components/ThoughtProcess";
import FooterBamise from "../components/FooterBamise";
import MobileThoughtProcessPage from "../components/MobileThoughtProcessPage";
import AboutBamiseNavBar from "../components/AboutBamiseNavBar";

const ThoughtProcessPage = ({ setIsHomeLink }) => {
  const targetRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const stickyElement = useRef(null);
  const [menuBool, setMenuBool] = useState(false);

  // Function to handle the calculation of width and scroll height
  const calculateDimensions = () => {
    if (cardsContainerRef.current && targetRef.current) {
      const cardsWidth = cardsContainerRef.current.scrollWidth;
      const viewportWidth = targetRef.current.offsetWidth;

      setContainerWidth(cardsWidth);
      setViewportWidth(viewportWidth);

      // Dynamically calculate the height based on content width
      const totalScrollHeight =
        (cardsWidth / viewportWidth) * window.innerHeight;
      setScrollHeight(totalScrollHeight);
    }
  };

  useLayoutEffect(() => {
    // Perform the initial calculation
    calculateDimensions();

    // Add a resize event listener to recalculate on window resize
    const handleResize = debounce(() => {
      calculateDimensions();
    }, 300); // 300ms debounce to prevent excessive function calls

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [0, -(containerWidth - viewportWidth)] // Stop at the last card
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const [isCase, setIsCase] = useState(false);

  return (
    <>
      {!isMobile ? (
        <div
          className="about-page thought-process"
          ref={targetRef}
          style={{ scrollBehavior: "smooth" }}
        >
          <Helmet>
            <title>
              Blueprint - Work | RequestButton - Innovative Digital Solution
            </title>
          </Helmet>
          <div className="dead" ref={stickyElement}></div>
          <AboutBamiseNavBar
            setIsHomeLink={setIsHomeLink}
            // setBackExist={setBackExist}
          />
          <AnimatePresence mode="wait">
            {menuBool && (
              <MenuContainer error={true} setMenuBool={setMenuBool} />
            )}
          </AnimatePresence>
          {/* <BurgerMenu
            ref={stickyElement}
            menuBool={menuBool}
            setMenuBool={setMenuBool}
            currentIndex={1}
          /> */}

          <div
            className="h-screen sticky top-0 flex items-center overflow-hidden about-container"
            style={{
              background: `url("/svgs/home_background_black.svg")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img src="/svgs/home_background.svg" alt="" className="work-bg" />

            <motion.div
              ref={cardsContainerRef}
              style={{ x }}
              className="flex fixed top-0"
            >
              {/* <ProjectContent {...project} projects={projects} />
              <ProjectContent {...project} projects={projects} />
              <ProjectContent {...project} projects={projects} /> */}
              <ThoughtProcess />

              {/* <AboutWeCanHelp />
              <AboutWeCanHelp />
              <AboutWeCanHelp />
              <NextCase setIsCase={setIsCase} {...nextProject} />
              <NextCase setIsCase={setIsCase} {...nextProject} />
              <NextCase setIsCase={setIsCase} {...nextProject} /> */}
              {/* <NextCase setIsCase={setIsCase} {...nextProject} /> */}
              <FooterBamise setIsHomeLink={setIsHomeLink} />
            </motion.div>
          </div>

          <StickyCursor currentIndex={1} stickyElement={stickyElement} />
          <StickyCase isCase={isCase} />
          <PageTransition pageTitle={"Thought Process"} />
        </div>
      ) : (
        <MobileThoughtProcessPage setIsHomeLink={setIsHomeLink} />
      )}
    </>
  );
};

export default ThoughtProcessPage;
