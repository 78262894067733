import React from "react";
import "../css/menu-container.css";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuSlide, menuSlideContact, menuSlideWork, menuError } from "./anim";
import Curve from "./curve";
import MenuActionBtn from "./MenuActionBtn";
import BtnMagnetic from "./ButtonMagnetic";
import { toggleOff } from "../redux/isFilledSlice";
import { useDispatch } from "react-redux";
import GrabMagnetic from "./GrabMagnetic";

const MenuContainer = ({
  work,
  contact,
  error,
  setCurrentIndex,
  setMenuBool,
  setBackExist,
  setIsHomeLink,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
      logoImg: "/images/safulpay_logo.png",
    },
    {
      title: "Immunocore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "rgba(128, 255, 211, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
      logoImg: "/images/immunocore_logo.png",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "rgba(255, 51, 238, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
      logoImg: "/images/endo_logo.png",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_longimage.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "rgba(255, 204, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "rgba(138, 43, 226, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
    },
  ];

  const bamiseProjects = [
    {
      title: "jarvis",
      src: "/images/jarvis.png",
      logoImg: "/images/jarvis.png",
      color: "#33ffe0",
      background: "rgba(51, 255, 224, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/bamise/work/jarvis",
      details:
        "Jarvis isn’t just another app—it’s the bridge between where you are and where you’re meant to be. It holds secrets of the present and possibilities for the future.",
      location: "Global",
      year: 2024,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/bamise/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
      logoImg: "/images/safulpay_logo.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      logoImg: "/images/trigger_owned.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/bamise/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/bamise/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },

    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/bamise/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_longimage.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "rgba(255, 204, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/bamise/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "rgba(138, 43, 226, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/bamise/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
    },
  ];

  return (
    <motion.div
      variants={
        work
          ? contact
            ? menuSlideContact
            : menuSlideWork
          : !error
          ? menuSlide
          : menuError
      }
      className="menu-container"
      //   initial={{
      //     x: "-110%",
      //   }}
      //   animate={{
      //     x: "6%",
      //   }}
      //   exit={{
      //     x: "-110%",
      //   }}
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <div className="content">
        <div className="title-content">
          <h2>Projects</h2>
          <BtnMagnetic>
            <MenuActionBtn
              text="More projects"
              handleNext={() => {
                setMenuBool(false);
                navigate(
                  location.pathname.split("/").includes("bamise")
                    ? "/bamise/projects"
                    : "/projects"
                );
              }}
            />
          </BtnMagnetic>
        </div>
        {location.pathname.split("/").includes("bamise") ? (
          <div className="locations">
            {bamiseProjects.slice(0, 3).map((project) => (
              <Link to={project.link} className="location">
                <div className="head">{project.title}</div>
                <div className="text">Locations: {project.location}</div>
                <img src={project.logoImg} alt="" />
                <div className="body">{project.details}</div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="locations">
            {projects.slice(0, 3).map((project) => (
              <Link to={project.link} className="location">
                <div className="head">{project.title}</div>
                <div className="text">Locations: {project.location}</div>
                <img src={project.logoImg} alt="" />
                <div className="body">{project.details}</div>
              </Link>
            ))}
          </div>
        )}
      </div>

      {location.pathname.split("/").includes("bamise") ? (
        <div className="footer">
          <Link
            to="/bamise"
            onClick={() => {
              setMenuBool(false);
            }}
            className="link"
          >
            <div className="left">
              <h3>About</h3>
              <p>
                Learn about how BluePrint <br /> brings your ideas to reality
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
          <Link
            to="/bamise/contact-bamise"
            onClick={() => {
              setMenuBool(false);
            }}
            className="link"
          >
            <div className="left">
              <h3>Contact Me</h3>
              <p>
                Ready to bring your <br /> ideas to reality?
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
          <Link
            to="/bamise/thought-process"
            onClick={() => {
              setMenuBool(false);
            }}
            className="link"
          >
            <div className="left">
              <h3>Thought Process</h3>
              <p>
                A bit of what goes on in <br /> my head when designing
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
        </div>
      ) : (
        <div className="footer">
          <Link
            onClick={() => {
              if (location.pathname === "/") {
                setIsHomeLink(true);
                setCurrentIndex(1);
                setMenuBool(false);
                dispatch(toggleOff());
                setBackExist(true);
              } else {
                setIsHomeLink(true);
              }
            }}
            to="/"
            className="link"
          >
            <div className="left">
              <h3>Get Started</h3>
              <p>
                See things from <br /> the beginning
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
          <Link
            to="/about-us"
            onClick={() => {
              setMenuBool(false);
            }}
            className="link"
          >
            <div className="left">
              <h3>About Us</h3>
              <p>
                Learn about how BluePrint <br />
                brings your ideas to reality
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
          <Link
            to="/contact-us"
            onClick={() => {
              setMenuBool(false);
            }}
            className="link"
          >
            <div className="left">
              <h3>Contact Us</h3>
              <p>
                Ready to bring your <br /> ideas to reality?
              </p>
            </div>
            <div className="right">
              <GrabMagnetic>
                <div className="icon-container">
                  <img src="/svgs/angle-right.svg" alt="" />
                </div>
              </GrabMagnetic>
            </div>
          </Link>
        </div>
      )}
      <Curve />
    </motion.div>
  );
};

export default MenuContainer;
