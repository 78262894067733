import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "../../css/about/meet-team.css";
// import RoundedButton from "../RoundedButton";
import { Link } from "react-router-dom";
import RoundedButton from "../../components/RoundedButton";

const MeetBamise = () => {
  return (
    <div className="meet-container meet-bamise-container">
      <div className="content">
        <div className="ctn-title">
          <h4>Meet Bamise</h4>
        </div>
        <div className="keys">
          <h1>Innovative.</h1>
          <h1>User-Centric.</h1>
          <h1>Speed.</h1>
        </div>
      </div>

      <div className="images">
        {/* Second Image */}

        <Link
          to="https://www.linkedin.com/in/yusuf-oluwatimileyin/"
          target="_blank"
          className={`img bamise`}
        >
          <img src="/images/timi_head_transparent.png" alt="" />
          <div className="ctn">
            <div className="left">
              <h3>Bamise Timi</h3>
              <p>
                At Blueprint, I merge
                <br /> creativity with functionality to
                <br /> ensure every design is as <br />
                practical as it is beautiful.
              </p>
            </div>
            <div className="right">
              <div className="scope">
                <div className="career">Designer</div>
              </div>
              <div className="social">
                <div className="icon">
                  <img src="/svgs/linked_work.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MeetBamise;
