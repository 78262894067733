import React from "react";
import "../../css/about/about-portofolio.css";
// import BtnMagnetic from "../ButtonMagnetic";
// import ProceedBtn from "../ProceedBtn";
// import PortofolioContent from "./PortofolioContent";
import { useLocation, useNavigate } from "react-router-dom";
import BtnMagnetic from "../../components/ButtonMagnetic";
import ProceedBtn from "../../components/ProceedBtn";
import PortofolioContent from "../../components/about components/PortofolioContent";

const AboutBamisePortofolio = ({ setIsPortofolio, setModal, projects }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="about-portofolio">
      <h1>PROJECTS</h1>
      <div className="content">
        <div className="group">
          <h3
            className={`${
              location.pathname.split("/").includes("bamise") && "bamise"
            }`}
          >
            Designed for Impact: Projects <br /> That Speak for Themselves
          </h3>
          {/* <p className="first">
            Explore the projects we’ve crafted with passion,
            <br /> precision, and a deep understanding of user
            <br /> needs.
          </p> */}
        </div>
        <p>
          I believe great design should tell its own <br />
          story. Below is a curated selection of
          <br /> projects that showcase my expertise in
          <br /> product design across various industries. <br />
          Each project reflects a commitment to
          <br /> usability, innovation, and impactful
          <br /> solutions tailored to unique challenges
          <br /> and user needs.
        </p>
        <div className="btn-ctrn">
          <BtnMagnetic>
            <ProceedBtn
              text="All projects"
              handleNext={() => navigate("/bamise/projects")}
            />
          </BtnMagnetic>
        </div>
      </div>

      <PortofolioContent
        setModal={setModal}
        setIsPortofolio={setIsPortofolio}
        projects={projects}
      />
    </div>
  );
};

export default AboutBamisePortofolio;
