import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "../../css/about/about-help-container.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const AboutBamiseWeCanHelp = () => {
  const slideTextRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const element = slideTextRef.current;

    // Infinite vertical movement animation
    const animation = gsap.to(element, {
      y: 2000, // Adjust the distance based on your design
      duration: 12, // Adjust duration for speed
      ease: "none",
      repeat: -1, // Infinite repeat
      yoyo: true, // Reverse the direction after each iteration
    });

    // Scroll-triggered direction control
    ScrollTrigger.create({
      trigger: element,
      start: "top bottom", // When the top of the element hits the bottom of the viewport
      end: "bottom top", // When the bottom of the element hits the top of the viewport
      scrub: true, // Link the scroll to the animation progress
      onUpdate: (self) => {
        // Adjust animation speed based on scroll direction
        animation.timeScale(self.direction === 1 ? 1 : -1); // 1 for down, -1 for up
      },
    });

    return () => {
      animation.kill();
    };
  }, []);
  return (
    <div className="about-help-container">
      <div className="scope" ref={slideTextRef}>
        <div className="slide-text">I CAN HELP YOU WITH</div>
      </div>
      <div
        className={`home-section ${
          location.pathname.split("/").includes("bamise") && "bamise"
        }`}
      >
        <div className="first-frame">
          <div className="card">
            <h2>Design</h2>
          </div>
          <div className="card arrow-card first">
            <h3>3. Visual Design</h3>
            <p>
              Once the structure is defined, we focus on the look and feel of
              the product. We combine color schemes, typography, and branding
              elements to create a visually appealing design that enhances
              usability.
            </p>
            <img
              src="/svgs/Arrow-straight.svg"
              className="arrow-straight"
              alt=""
            />
            <img src="/svgs/Arrow-up.svg" alt="" className="arrow-up" />
          </div>
          <div className="card">
            <h3>4. Usability Testing</h3>
            <p>
              We conduct thorough usability testing to gather real user
              feedback. This allows us to make adjustments before development,
              ensuring the product meets user expectations and offers a seamless
              experience.
            </p>
          </div>
          <div className="card arrow-card first">
            <h3>1. User Research</h3>
            <p>
              We begin by understanding your users through comprehensive
              research, interviews, and data analysis. This helps us align the
              design with user needs and business goals.
            </p>
            <img
              src="/svgs/Arrow-straight.svg"
              className="arrow-straight"
              alt=""
            />
          </div>
          <div className="card">
            <h3>2. Wireframing & Prototyping</h3>
            <p>
              Our team creates wireframes that outline the layout and structure
              of your app or website. This is followed by interactive
              prototypes, allowing you to visualize the user experience and
              provide feedback before moving to the next phase.
            </p>
          </div>
        </div>
        {/* <div className="middle-card">
          <h1>Transition & Collaboration Approach</h1>
          <p>
            1. Design-First, Then Development
            <br /> 2. Agile Collaboration: Sprint-Based Workflow
            <br /> 3. Simultaneous Design & Development
          </p>
          <img src="/svgs/Arrow-left.svg" className="left" alt="" />
          <img src="/svgs/Arrow-right-curl.svg" className="right" alt="" />
        </div> */}
        {/* <div className="first-frame">
          <div className="card">
            <h2>Development</h2>
          </div>
          <div className="card arrow-card first">
            <h3>3. Cross-Platform Solutions</h3>
            <p>
              Our development process ensures that your product functions
              seamlessly across different platforms—whether it's desktop,
              mobile, or tablet. We prioritize responsive design and
              cross-platform compatibility to reach the widest audience
              possible.
            </p>
            <img
              src="/svgs/Arrow-straight.svg"
              className="arrow-straight"
              alt=""
            />
            <img src="/svgs/Arrow-up.svg" alt="" className="arrow-up" />
          </div>
          <div className="card">
            <h3>4. Quality Assurance & Testing</h3>
            <p>
              We conduct thorough usability testing to gather real user
              feedback. This allows us to make adjustments before development,
              ensuring the product meets user expectations and offers a seamless
              experience.
            </p>
          </div>
          <div className="card arrow-card first">
            <h3>1. Custom Web & App Development</h3>
            <p>
              Our development team builds powerful and scalable websites and
              apps tailored to your unique business needs. We follow a
              meticulous process to ensure clean, efficient code that powers
              fast, responsive, and secure digital experiences.
            </p>
            <img
              src="/svgs/Arrow-straight.svg"
              className="arrow-straight"
              alt=""
            />
          </div>
          <div className="card">
            <h3>2. Full-Stack Development</h3>
            <p>
              From front-end user interfaces to back-end server architecture, we
              handle every aspect of the development process. Whether it’s
              integrating APIs, databases, or advanced functionalities, we
              ensure everything works harmoniously together.
            </p>
          </div>
          <div className="card arrow-card first">
            <h3>5. Ongoing Maintenance & Updates</h3>
            <p>
              After launch, we continue to support you with regular updates, new
              features, and maintenance. Our goal is to keep your app or website
              running smoothly, secure, and up-to-date with the latest
              technology advancements.
            </p>
            <img
              src="/svgs/Arrow-down-conne.svg"
              className="arrow-down"
              alt=""
            />
          </div>
        </div> */}
        <div className="additional-card">
          <h1>
            + <br />
            Additional Services
          </h1>
        </div>
        <div className="other-features">
          <div className="card">
            <h3>Branding</h3>
            <p>
              We provide branding services to help you establish a unique and
              recognizable identity. From logos to complete brand guidelines, we
              create visual elements that communicate your brand’s values
              effectively.
            </p>
          </div>
          {/* <div className="card">
            <h3>SEO Optimization</h3>
            <p>
              Our websites and apps are built with SEO in mind, ensuring they
              rank well on search engines. We implement best practices to
              improve visibility, drive traffic, and help your business grow
              organically.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutBamiseWeCanHelp;

/**
 *   */
