import React, { useEffect, useRef, useState } from "react";
// import PageTransition from "../components/PageTransition";
import "../../css/contact/contact-us.css";
import RoundedButton from "../../components/RoundedButton";
import StickyCursor from "../../components/StickyCursor";
import Navbar from "../../components/Navbar";
import { AnimatePresence } from "framer-motion";
import MenuContainer from "../../components/MenuContainer";
import BurgerMenu from "../../components/BurgerMenu";
import MenuMobileContainer from "../../components/MenuMobileContainer";
import { motion } from "framer-motion";
import HomeContentThanks from "../../components/HomeContentThanks";
import Lottie from "lottie-react";
import Loader from "../../lotties/Animation - 1728381102131.json";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
import PageTransition from "../../components/PageTransition";

const ContactBamise = ({ setIsHomeLink }) => {
  const stickyElement = useRef(null);
  const [menuBool, setMenuBool] = useState(false);
  const [menuRepoBool, setMenuRepoBool] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [screenLoadBool, setScreenLoadBool] = useState(true);
  const [navMobileThanks, setNavMobileThanks] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [imgBool, setImgBool] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    comment: "",
    organisation: "",
    service: "",
  });

  const changeHandler = (e) => {
    setErrorMessage("");
    setLoadingBool(false);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   if (!formState.email || !formState.comment) {
  //   } else {

  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      setScreenLoadBool(false);
    }, [300]);
  }, []);

  useEffect(() => {
    if (!isAnimating) {
      setNavMobileThanks(false);
    }
  }, [isAnimating]);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const imgAnimationVariants = {
    hidden: {
      right: isMobile ? "-20%" : "-20%", // Start from -10% from the right
    },
    animate: {
      right: isMobile
        ? ["-20%", "-18%", "40%", "40%"]
        : ["-20%", "-18%", "43%", "43%"], // Adjusted for mobile
      scale: [1, 1, 1, isMobile ? 1.8 : 2], // Scale up to 2 at 43%
      transition: {
        duration: 2, // Total duration for the entire animation sequence
        times: [0, 0.2, 0.8, 1, 1], // Define the timing
        ease: "backInOut",
      },
    },
    stop: {
      right: "-20%",
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const imgAnimationVariants2 = {
    hidden: {
      right: isMobile ? "40%" : "43%", // Start from -10% from the right
    },
    bounceOut: {
      right: isMobile ? ["40%", "0%", "200%"] : ["43%", "0%", "120%"],
      transition: {
        delay: 0.2,
        duration: 1,
        times: [0, 0.6, 1],
        ease: ["easeInOut", "easeOut", "backInOut"],
      },
    },
  };

  useEffect(() => {
    if (isAnimating) {
      // Set a timeout that matches the duration of the image animation
      const timer = setTimeout(() => {
        setImgBool(true);
      }, 2000); // Duration of the image animation (2 seconds)

      // Clear the timeout if the component unmounts or if isAnimating changes
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoadingBool(true);

    emailjs
      .send(
        "service_00ycw0m", // Your EmailJS service ID
        "template_fn2kh6b", // Your EmailJS template ID
        {
          name: formState.name,
          email: formState.email,
          message: formState.comment,
          organisation: formState.organisation,
          service: formState.service,
        },
        "badD0FjqLJBP_4fNT" // Your EmailJS user ID
      )
      .then((response) => {
        if (response.status === 200) {
          setLoadingBool(false);
          setIsAnimating(!isAnimating);
          setNavMobileThanks(true);
        } else {
          setErrorMessage("Failed to send message, please try again");
          setLoadingBool(false);
        }
      })
      .catch((err) => {
        console.error("Failed to send email: ", err);
        setErrorMessage("Failed to send message, please try again");
        setLoadingBool(false);
      });
  };

  return (
    <>
      {!isMobile ? (
        <form onSubmit={handleSubmit} className="contact-us">
          <Helmet>
            <title>
              Blueprint - Contact Us | Get in Touch for Digital Solutions
            </title>
          </Helmet>
          <PageTransition pageTitle={"Contact Bamise"} />

          {!screenLoadBool && (
            <>
              <Navbar ref={stickyElement} currentIndex={1} />

              <motion.div
                initial={{ x: "0%" }}
                animate={{ x: isAnimating ? "-200%" : "0%" }}
                transition={{
                  delay: 0.6,
                  duration: 1,
                  ease: "circInOut",
                }}
                className="contact-container"
              >
                <div className="container-wrapper">
                  <h1>
                    Let’s bring your <br /> next project to <br />
                    life—ready to <br />
                    collaborate?
                  </h1>
                  <div className="form">
                    {loadingBool && (
                      <div className="animation">
                        <Lottie animationData={Loader} />
                      </div>
                    )}
                    <div className="label first-child">
                      <div className="wrap-label">
                        <label htmlFor="">What is your name?</label>
                        {errorMessage && <p>{errorMessage}</p>}
                      </div>
                      <input
                        type="text"
                        placeholder="Bamise *"
                        required
                        value={formState.name}
                        name="name"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">What's your email</label>
                      <input
                        type="email"
                        placeholder="help@bamise.com *"
                        required
                        name="email"
                        value={formState.email}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">
                        What's the name of your organisation?
                      </label>
                      <input
                        type="text"
                        name="organisation"
                        value={formState.organisation}
                        onChange={changeHandler}
                        placeholder="Bamise & Co *"
                        required
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">
                        What service are you looking for?
                      </label>
                      <input
                        type="text"
                        name="service"
                        placeholder="Web Design, App Development..."
                        value={formState.service}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">Your message</label>
                      <textarea
                        placeholder="Hello Bamise, can you help me with...*"
                        required
                        name="comment"
                        value={formState.comment}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>

                <div className="contact-footer">
                  <div className="details">
                    <div className="contact-details">
                      <h2>CONTACT DETAILS</h2>
                      <a href="mailto:Yusuf.oluwatimileyin94@gmail.com">
                        Yusuf.oluwatimileyin94@gmail.com
                      </a>
                      <a href="tel:+2349060100257">+234 906 010 0257</a>
                      <a>Location: Ibadan, Oyo State, Nigeria & Near you</a>
                    </div>
                  </div>
                  <div className="stretch">
                    <RoundedButton
                      backgroundColor="#37bb9ed3"
                      height={150}
                      width={150}
                      submit={true}
                    >
                      <p>Send it</p>
                    </RoundedButton>
                  </div>
                </div>
              </motion.div>

              <HomeContentThanks dark={true} animated={isAnimating} />

              <motion.img
                src="/svgs/blueprint_lg.svg"
                className={`img-bp ${imgBool && "hide"}`}
                alt=""
                initial="hidden" // Initial state off-screen
                animate={isAnimating ? "animate" : "stop"} // Animate when `isAnimating` is true
                variants={imgAnimationVariants}
              />
              <motion.img
                initial="hidden"
                animate={imgBool ? "bounceOut" : "hidden"} // Animate when `imgBool` is true
                variants={imgAnimationVariants2}
                src="/svgs/blueprint_lg.svg"
                className={`img-bpp ${imgBool && "reveal"}`}
                alt=""
              />

              <AnimatePresence mode="wait">
                {menuBool && (
                  <MenuContainer
                    setMenuBool={setMenuBool}
                    contact={true}
                    work={true}
                    setIsHomeLink={setIsHomeLink}
                  />
                )}
              </AnimatePresence>
              <BurgerMenu
                ref={stickyElement}
                menuBool={menuBool}
                setMenuBool={setMenuBool}
                currentIndex={1}
              />
              <StickyCursor currentIndex={1} stickyElement={stickyElement} />
            </>
          )}
        </form>
      ) : (
        <form action="" onSubmit={handleSubmit} className="contact-mobile">
          <Helmet>
            <title>
              Blueprint - Contact Us | Get in Touch for Digital Solutions
            </title>
          </Helmet>
          {!screenLoadBool && (
            <>
              <img src="/svgs/contact_bg.svg" alt="" className="mobile-bg" />
              <Navbar
                setIsHomeLink={setIsHomeLink}
                currentIndex={1}
                setMenuRepoBool={setMenuRepoBool}
                contact={true}
                workMb={true}
              />
              <AnimatePresence mode="wait">
                {menuRepoBool && (
                  <MenuMobileContainer
                    setIsHomeLink={setIsHomeLink}
                    setMenuRepoBool={setMenuRepoBool}
                  />
                )}
              </AnimatePresence>
              <motion.div
                initial={{ x: "0%" }}
                animate={{ x: isAnimating ? "-500%" : "0%" }}
                transition={{
                  delay: 0.6,
                  duration: 1,
                  ease: "circInOut",
                }}
                className="stretch"
              >
                <RoundedButton
                  backgroundColor="#37bb9ed3"
                  height={150}
                  width={150}
                  submit={true}
                >
                  <p>Send it</p>
                </RoundedButton>
              </motion.div>
              <motion.div
                initial={{ x: "0%" }}
                animate={{ x: isAnimating ? "-200%" : "0%" }}
                transition={{
                  delay: 0.6,
                  duration: 1,
                  ease: "circInOut",
                }}
                className="contact-container"
              >
                <div className="container">
                  <div className="contact-title">
                    <h1 style={{ width: "90%" }}>
                      Let’s bring your next project to life - ready to
                      collaborate?
                    </h1>
                  </div>
                  <div className="form">
                    {loadingBool && (
                      <div className="animation">
                        <Lottie animationData={Loader} />
                      </div>
                    )}
                    <div className="label first-child">
                      <div className="wrap-label">
                        <label htmlFor="">What is your name?</label>
                        {errorMessage && <p>{errorMessage}</p>}
                      </div>
                      <input
                        type="text"
                        placeholder="Bamise *"
                        required
                        value={formState.name}
                        name="name"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">What's your email</label>
                      <input
                        type="email"
                        placeholder="help@bamise.com *"
                        required
                        name="email"
                        value={formState.email}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">
                        What's the name of your organisation?
                      </label>
                      <input
                        type="text"
                        placeholder="Bamise & Co *"
                        required
                        value={formState.organisation}
                        name="organisation"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">
                        What service are you looking for?
                      </label>
                      <input
                        type="text"
                        placeholder="Web Design, App Development..."
                        value={formState.service}
                        name="service"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="label">
                      <label htmlFor="">Your message</label>
                      <textarea
                        placeholder="Hello Bamise, can you help me with...*"
                        required
                        name="comment"
                        value={formState.comment}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>

              <HomeContentThanks dark={true} animated={isAnimating} />

              <motion.img
                src="/svgs/blueprint_lg.svg"
                className={`img-bp ${imgBool && "hide"}`}
                alt=""
                initial="hidden" // Initial state off-screen
                animate={isAnimating ? "animate" : "stop"} // Animate when `isAnimating` is true
                variants={imgAnimationVariants}
              />
              <motion.img
                initial="hidden"
                animate={imgBool ? "bounceOut" : "hidden"} // Animate when `imgBool` is true
                variants={imgAnimationVariants2}
                src="/svgs/blueprint_lg.svg"
                className={`img-bpp ${imgBool && "reveal"}`}
                alt=""
              />
            </>
          )}
          <PageTransition pageTitle={"Contact Bamise"} />
        </form>
      )}
    </>
  );
};

export default ContactBamise;
