import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/about/about-nav.css";

const AboutBamiseNavBar = ({}) => {
  const location = useLocation();

  return (
    <div
      className={`about-navbar ${
        location.pathname.split("/").includes("thought-process") &&
        "thought-process"
      }`}
    >
      <Link className="logo">
        <div className="scope scope-bamise">
          <p>Bamise Oluwatimileyin</p>
        </div>
        <img src="/images/bamise_profile.png" alt="" />
      </Link>

      <div className="links links-bamise">
        <div className="scope">
          <Link to="/bamise/projects">Projects</Link>
        </div>
        <div className="scope">
          <Link
            to="/bamise/thought-process"
            className={`${
              location.pathname.split("/").includes("thought-process") &&
              "active"
            }`}
          >
            Thought Process{" "}
          </Link>
        </div>
        <div className="scope">
          <Link to="/bamise/contact-bamise">Contact Me </Link>
        </div>
        <div className="scope">
          {location.pathname.split("/").includes("thought-process") ? (
            <Link to="/bamise">About Me</Link>
          ) : (
            <Link to="/bamise" className="active">
              About Me
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutBamiseNavBar;
