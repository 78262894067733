import React from "react";
import "../css/menu-mobile-container.css";
import { Link, useLocation } from "react-router-dom";
import { menuSlide, menuRepoSlide } from "./anim";
import { motion } from "framer-motion";
import Curve from "./curve";

const MenuMobileContainer = ({ setMenuRepoBool, setIsHomeLink }) => {
  const location = useLocation();

  return (
    <motion.div
      variants={menuRepoSlide}
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
      initial="initial"
      animate="enter"
      exit="exit"
      className="menu-mobile-container"
    >
      <div className="bar">
        <div className="icon-bg" onClick={() => setMenuRepoBool(false)}>
          <img src="/svgs/collapse.svg" alt="" />
        </div>
      </div>
      <div className="wrap">
        <Link
          to={
            location.pathname.split("/").includes("bamise")
              ? "/bamise/projects"
              : "/projects"
          }
          onClick={() => setMenuRepoBool(false)}
          className="auth-contain"
        >
          <h1>Projects</h1>
          <img src="/svgs/angle-right.svg" alt="" />
        </Link>
        <div className="rest-contents">
          {location.pathname.split("/").includes("bamise") ? (
            <div className="links">
              <Link
                to="/bamise"
                onClick={() => setMenuRepoBool(false)}
                className="link"
              >
                <div className="left">
                  <h3>About Me</h3>
                  <p>
                    Learn about how BluePrint <br />
                    brings your ideas to reality
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
              <Link
                to="/bamise/contact-bamise"
                onClick={() => setMenuRepoBool(false)}
                className="link"
              >
                <div className="left">
                  <h3>Contact me</h3>
                  <p>
                    Ready to bring your <br /> ideas to reality?
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
              <Link
                to="/bamise/thought-process"
                onClick={() => setMenuRepoBool(false)}
                className="link"
              >
                <div className="left">
                  <h3>Thought Process</h3>
                  <p>
                    A bit of what goes on in my <br /> head when designing
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <div className="links">
              <Link
                to="/"
                onClick={() => {
                  setIsHomeLink(true);
                  setMenuRepoBool(false);
                }}
                className="link"
              >
                <div className="left">
                  <h3>Get Started</h3>
                  <p>
                    See things from <br /> the beginning
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
              <Link
                to="/about-us"
                onClick={() => setMenuRepoBool(false)}
                className="link"
              >
                <div className="left">
                  <h3>About Us</h3>
                  <p>
                    Learn about how BluePrint <br />
                    brings your ideas to reality
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
              <Link
                to="/contact-us"
                onClick={() => setMenuRepoBool(false)}
                className="link"
              >
                <div className="left">
                  <h3>Contact Us</h3>
                  <p>
                    Ready to bring your <br /> ideas to reality?
                  </p>
                </div>
                <div className="right">
                  <div className="icon-container">
                    <img src="/svgs/angle-right.svg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          )}
          <div className="footer-contents">
            <p>Blueprint is the Future of Design and Innovation</p>
            {location.pathname.split("/").includes("bamise") ? (
              <div className="socials">
                <div className="txt">Follow Us</div>
                <div className="icons">
                  <Link
                    to="https://www.linkedin.com/in/yusuf-oluwatimileyin/"
                    target="_blank"
                  >
                    <img src="/svgs/linkedin_last_black.svg" alt="" />
                  </Link>
                  <Link
                    to="https://wa.me/message/L7CYQQER36CYH1"
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                  >
                    <img src="/svgs/WA_Logo.svg" alt="" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="socials">
                <div className="txt">Follow Us</div>
                <div className="icons">
                  <Link
                    to="https://www.linkedin.com/company/chooseblueprint"
                    target="_blank"
                  >
                    <img src="/svgs/linkedin_last_black.svg" alt="" />
                  </Link>
                  <img src="/svgs/instagram.svg" alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Curve />
    </motion.div>
  );
};

export default MenuMobileContainer;
