import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import AboutNavBar from "../../components/about components/AboutNavBar";
import StickyCursor from "../../components/StickyCursor";
import { debounce } from "lodash";
// import AboutPortofoilo from "../../components/about components/AboutPortofoilo";
// import MeetTheTeam from "../../components/about components/MeetTheTeam";
// import Footer from "../../components/about components/Footer";
import StickMenuContainer from "../../components/about components/StickMenuContainer";
import AboutOverview from "../../components/about components/AboutOverview";
import PageTransition from "../../components/PageTransition";
import { Helmet } from "react-helmet";
import AboutMobileSection from "../../components/about mobile components/AboutMobileSection";
import StickProceedIcon from "../../components/about components/StickProceedIcon";
import AboutBamiseNavBar from "../components/AboutBamiseNavBar";
import AboutBamiseLanding from "../components/AboutBamiseLanding";
import "../../css/about/about.css";
import AboutBamiseWeCanHelp from "../components/AboutBamiseWeCanHelp";
import AboutBamisePortofolio from "../components/AboutBamisePortofolio";
import MeetBamise from "../components/MeetBamise";
import FooterBamise from "../components/FooterBamise";
import AboutBamiseMobileSection from "../components/AboutBamiseMobileSection";
// import AboutLanding from "../../components/about components/AboutLanding";

const AboutBamise = ({ setBackExist, setIsHomeLink, individualDetails }) => {
  const targetRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const stickyElement = useRef(null);
  const [isPortofolio, setIsPortofolio] = useState(false);
  const [modal, setModal] = useState({ active: false, index: 0 });

  const projects = [
    {
      title: "jarvis",
      src: "/images/jarvis_bd.png",
      logoImg: "/images/jarvis.png",
      color: "#33ffe0",
      background: "rgba(51, 255, 224, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/jarvis",
      bamiseLink: "/bamise/work/jarvis",
      details:
        "Jarvis isn’t just another app—it’s the bridge between where you are and where you’re meant to be. It holds secrets of the present and possibilities for the future.",
      location: "Global",
      year: 2025,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "SAFULPAY",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      bamiseLink: "/bamise/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "REQUESTBUTTON",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      bamiseLink: "/bamise/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
    },
    {
      title: "TRIGGER",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      bamiseLink: "/bamise/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      bamiseLink: "/bamise/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Desgin",
    },
  ];

  // Function to handle the calculation of width and scroll height
  const calculateDimensions = () => {
    if (cardsContainerRef.current && targetRef.current) {
      const cardsWidth = cardsContainerRef.current.scrollWidth;
      const viewportWidth = targetRef.current.offsetWidth;

      setContainerWidth(cardsWidth);
      setViewportWidth(viewportWidth);

      // Dynamically calculate the height based on content width
      const totalScrollHeight =
        (cardsWidth / viewportWidth) * window.innerHeight;
      setScrollHeight(totalScrollHeight);
    }
  };

  useLayoutEffect(() => {
    // Perform the initial calculation
    calculateDimensions();

    // Add a resize event listener to recalculate on window resize
    const handleResize = debounce(() => {
      calculateDimensions();
    }, 300); // 300ms debounce to prevent excessive function calls

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [0, -(containerWidth - viewportWidth)] // Stop at the last card
  );

  const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const maxScroll = scrollHeight - clientHeight;

    window.scrollTo({
      top: maxScroll,
      behavior: "smooth",
    });
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return (
    <div
      className="about-page"
      ref={targetRef}
      style={{ scrollBehavior: "smooth" }}
    >
      <Helmet>
        <title>
          Blueprint - About Us | Your Partner in Crafting Innovative Digital
          Experiences
        </title>
      </Helmet>
      <div className="dead" ref={stickyElement}></div>
      <AboutBamiseNavBar
        setIsHomeLink={setIsHomeLink}
        setBackExist={setBackExist}
      />

      <div
        className="h-screen sticky top-0 flex items-center overflow-hidden about-container"
        style={{
          background: `url("/svgs/home_background_black.svg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img src="/svgs/home_background_black.svg" alt="" className="work-bg" />

        <motion.div
          ref={cardsContainerRef}
          style={{ x }}
          className="flex fixed top-0"
        >
          <AboutBamiseLanding
            scrollToEnd={scrollToBottom}
            individualDetails={individualDetails}
          />
          <AboutBamiseWeCanHelp />
          <AboutBamisePortofolio
            modal={modal}
            setModal={setModal}
            setIsPortofolio={setIsPortofolio}
            projects={projects}
          />
          {/* <AboutOverview /> */}
          <MeetBamise />
          {/* <HelpYou /> */}
          <FooterBamise setIsHomeLink={setIsHomeLink} />
        </motion.div>
      </div>

      <AboutBamiseMobileSection
        individualDetails={individualDetails}
        setIsHomeLink={setIsHomeLink}
        projects={projects}
      />

      <StickyCursor currentIndex={2} stickyElement={stickyElement} />
      <StickMenuContainer
        isPortofolio={isPortofolio}
        modal={modal}
        projects={projects}
      />
      <StickProceedIcon isPortofolio={isPortofolio} />
      <PageTransition pageTitle={"About Bamise"} />
    </div>
  );
};

export default AboutBamise;
