import React from "react";
import "../../css/work/project.content.css";
import { useLocation } from "react-router-dom";

const ThoughtProcess = () => {
  const location = useLocation();

  return (
    <div className="project-content thought-process" style={{ width: 15000 }}>
      <div className="screen-landing">
        <div className="screen-landing-title">
          My Design <br /> Thought <br /> Process
        </div>
        <div className="owned">
          Design is more than aesthetics—it’s about problem-solving, strategy,
          and user empathy. This page outlines my thought process when tackling
          a project, giving you insight into how I approach design challenges.
          <br /> <br />
          Unlike many project showcases that focus only on the final product, I
          believe in transparency, sharing the journey from ideation to
          execution.
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container">
          <div className="left">
            <div className="title">
              1.
              <br /> Understanding <br /> the Problem
            </div>
            <div className="content">
              Every successful design begins with understanding the core
              problem. I conduct interviews and surveys to gather insights on
              user needs, company goals, and potential pain points. <br />
              <br /> This helps shape problem statements, user stories, and
              journeys to ensure the solution is truly impactful.
            </div>
          </div>
          <div className="right">
            <img src="/svgs/Filling Survey.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container dark ">
          <div className="left">
            <div className="title">
              2. <br /> Competitive <br />
              Analysis
            </div>
            <div className="content">
              To create a standout product, I analyze competitors—identifying
              gaps, strengths, and opportunities.
              <br />
              <br /> This helps define a unique value proposition and ensures
              the project differentiates itself in a meaningful way.
            </div>
          </div>
          <div className="right">
            <img src="/svgs/Analyze Data.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container">
          <div className="left">
            <div className="title">
              3. <br /> Evaluating <br />
              Project <br /> Profitability
            </div>
            <div className="content">
              Prioritization is key. I assess how each feature contributes to
              business goals and user needs, ensuring that time and resources
              are allocated efficiently for maximum impact.
            </div>
          </div>
          <div className="right">
            <img src="/svgs/Financial Literacy.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container dark ">
          <div className="left">
            <div className="title">
              4. <br /> Conceptualizat
              <br /> ion & Early <br /> Sketches
            </div>
            <div className="content">
              Ideas start with rough sketches and low-fidelity prototypes. This
              stage allows rapid iteration, focusing on layout, structure, and
              functionality before diving into detailed designs.
            </div>
          </div>
          <div className="right height">
            <img
              src="/images/20 UX UI App Design Instagram Accounts You Should Follow.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container">
          <div className="left">
            <div className="title">
              5. <br /> Information <br />
              Architecture
            </div>
            <div className="content">
              Structuring content effectively is crucial for usability. I create
              sitemaps, navigation flows, and content hierarchies to ensure
              users can find information effortlessly. A well-thought-out
              information architecture improves user experience and interaction
              efficiency.
            </div>
          </div>
          <div
            className="right height"
            style={{ marginLeft: "10px", flex: 1.5 }}
          >
            <img
              //   style={{ width: "120%" }}
              src="/images/Information Architecture Familio Mobile App UX_UI.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container dark ">
          <div className="left">
            <div className="title">
              6. <br /> Building the <br /> Design System
            </div>
            <div className="content">
              A solid design system lays the foundation for consistency and
              scalability. This includes color palettes, typography, component
              libraries, and interaction guidelines to maintain visual harmony.
            </div>
          </div>
          <div className="right height">
            <img
              src="/images/MEDICO — Doctor-Patient Management System _UI_UX Design - Anna Dvornikova.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container">
          <div className="left">
            <div className="title">
              7. <br /> High-Fidelity <br /> Design & <br /> Interactive Mockups
            </div>
            <div className="content">
              Once the foundation is set, I transform concepts into polished
              high-fidelity designs with rich details and interactions. These
              mockups bring the vision to life, making it easier for
              stakeholders and developers to visualize the final product.
            </div>
          </div>
          <div style={{ marginLeft: "50px", flex: "1.3" }} className="right ">
            <img src="/images/Frame 16430.png" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container dark ">
          <div className="left">
            <div className="title">
              8. <br /> Prototyping for <br /> Better <br /> Communication
            </div>
            <div className="content">
              Interactive prototypes bridge the gap between design and
              development. They help stakeholders experience the product flow
              and ensure smooth handoffs to developers.
            </div>
          </div>
          <div className="right">
            <img src="/svgs/Group 1.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container">
          <div className="left">
            <div className="title">
              9. <br /> Testing & <br />
              Refinement (My <br />
              Favorite Part!)
            </div>
            <div className="content">
              Usability testing is crucial. I analyze user feedback, identify
              pain points, and refine designs accordingly. This iterative
              process ensures that the final product is intuitive and
              user-friendly.
            </div>
          </div>
          <div className="right ">
            <img src="/svgs/Listening To Feedback.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1230 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="framed-container dark ">
          <div className="left">
            <div className="title">
              10. <br /> Documentation <br /> & Handover
            </div>
            <div className="content">
              A well-documented design makes implementation seamless. I provide
              detailed specs, design rationale, and annotations to ensure a
              smooth transition from design to development.
            </div>
          </div>
          <div className="right">
            <img src="/svgs/Business Deal.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1598 }}>
        {/* <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        /> */}
        <div className="tools-container ">
          <div className="tools-section">
            <div className="title">Tools I Use</div>
            <div className="tools">
              <img
                src="/images/Figma_ The Collaborative Interface Design Tool.png"
                alt=""
              />
              <img src="/images/Adobe XD Logo.png" alt="" />
            </div>
          </div>
          <div className="strength-section">
            <div className="title">Strength Evaluation</div>
            <div className="strengths">
              <div className="strength">
                <p>User Experience (UX)</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "90%" }}></div>
                  </div>
                  <div className="progress">90%</div>
                </div>
              </div>
              <div className="strength">
                <p>User Interface (UI) - Mobile & Web</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "95%" }}></div>
                  </div>
                  <div className="progress">95%</div>
                </div>
              </div>
              <div className="strength">
                <p>Graphic Design</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "64%" }}></div>
                  </div>
                  <div className="progress">64%</div>
                </div>
              </div>
              <div className="strength">
                <p>Design Systems</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Information Architecture</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "80%" }}></div>
                  </div>
                  <div className="progress">80%</div>
                </div>
              </div>
              <div className="strength">
                <p>Prototyping & Microinteractions</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Usability Testing & Research</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "85%" }}></div>
                  </div>
                  <div className="progress">85%</div>
                </div>
              </div>
              <div className="strength">
                <p>Accessibility (A11y) & Inclusive Design</p>
                <div className="contain">
                  <div className="bar-container">
                    <div className="bar" style={{ width: "80%" }}></div>
                  </div>
                  <div className="progress">80%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThoughtProcess;
