import React from "react";
import "../../css/work/project.content.css";
import { useLocation } from "react-router-dom";

const ProjectFlexLanding = () => {
  const location = useLocation();

  return (
    <div className="project-content">
      <div className="screen-landing">
        <div className="screen-landing-title">
          PROJECT
          <br /> FLEX
        </div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            {location.pathname.split("/").includes("bamise") ? (
              <h2>Design</h2>
            ) : (
              <h2>Design & Development</h2>
            )}
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>Global</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectFlexLanding;
