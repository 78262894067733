import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import BtnMagnetic from "./ButtonMagnetic";

// Rename the function to a proper React component name (starting with an uppercase letter)
const Navbar = ({
  currentIndex,
  navMobileThanks,
  handlePrev,
  setMenuRepoBool,
  setIsHomeLink,
  contact,
  setCurrentIndex,
  workMb,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split("/").includes("bamise")) {
      //
    }
  });

  return (
    <div
      className={`navbar ${
        (currentIndex === 2 ||
          currentIndex === 5 ||
          currentIndex === 6 ||
          currentIndex === 0) &&
        "dark-nav"
      } ${workMb && "nav-tablet"} ${
        location.pathname.split("/").includes("bamise") && "dark-bamise-nav"
      }`}
    >
      <Link
        to={
          location.pathname.split("/").includes("bamise")
            ? "/bamise"
            : "/about-us"
        }
        className="logo"
      >
        <BtnMagnetic>
          {location.pathname.split("/").includes("bamise") ? (
            <img
              style={{ filter: "none" }}
              src="/images/bamise_profile_2.png"
              alt=""
            />
          ) : (
            <img src="/svgs/Bp_logo_dark.svg" alt="" />
          )}
        </BtnMagnetic>
        {location.pathname.split("/").includes("bamise") ? (
          <div className="logo-txt" style={{ textAlign: "start" }}>
            About <br /> Bamise
          </div>
        ) : (
          <div className="logo-txt">About Us</div>
        )}
      </Link>

      <div
        className={`nav-mobile ${
          currentIndex > 0 && !navMobileThanks && "change"
        }  ${
          (currentIndex === 1 || currentIndex === 3 || currentIndex === 4) &&
          "nav-mobile-dark"
        }`}
      >
        {currentIndex > 0 && !navMobileThanks && (
          <div
            className="icon-wrap icon-btn"
            onClick={() => (contact ? navigate(-1) : handlePrev())}
          >
            <img src="/svgs/angle-left.svg" alt="" />
          </div>
        )}
        <Link
          to={location.pathname.split("/").includes("bamise") ? "/bamise" : "/"}
          onClick={() => {
            setIsHomeLink(true);

            if (location.pathname === "/") {
              setCurrentIndex(0);
            }
          }}
          className="icon-wrap"
        >
          {location.pathname.split("/").includes("bamise") ? (
            <img
              src="/images/bamise_mobile_profile.png"
              className="logo_img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "none",
              }}
              alt=""
            />
          ) : (
            <img src="/svgs/logo_mobile.svg" className="logo_img" alt="" />
          )}
        </Link>
        <div
          className={`icon-wrap icon-burger ${
            currentIndex > 0 && !navMobileThanks && "icon-burger-mb"
          }`}
          onClick={() => {
            setMenuRepoBool(true);
          }}
        >
          <img src="/svgs/line_burger.svg" alt="" />
        </div>
      </div>

      {/* <img ref={ref} src="/svgs/burger.svg" alt="" className="burger" /> */}
      {/* <BurgerMenu ref={ref} /> */}

      {location.pathname.split("/").includes("bamise") ? (
        <div className="text">
          Shaping <br /> the Future
          <br /> of Design <br /> and <br /> Innovation.
        </div>
      ) : (
        <div className="text">
          Blueprint is <br /> the Future <br />
          of Design <br /> and <br /> Innovation
        </div>
      )}
    </div>
  );
};

export default Navbar;
