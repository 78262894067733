import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Work = ({
  index,
  title,
  setModal,
  setIsPortofolio,
  category,
  link,
  bamiseLink,
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <Link
      to={location.pathname.split("/").includes("bamise") ? bamiseLink : link}
      className="work"
      onMouseEnter={() => {
        setModal({ active: true, index });
        setIsPortofolio(true);
      }}
      onMouseLeave={() => {
        setModal({ active: false, index });

        setIsPortofolio(false);
      }}
    >
      <div
        className="title"
        onMouseLeave={() => setModal({ active: false, index })}
      >
        {title}
      </div>
      {location.pathname === "/about-us" && (
        <div
          className="category"
          onMouseLeave={() => setModal({ active: false, index })}
        >
          {category}
        </div>
      )}
    </Link>
  );
};

export default Work;
