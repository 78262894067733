import React from "react";
import "../../css/work/project.content.css";
import { useLocation } from "react-router-dom";

const RequestButton = () => {
  const location = useLocation();

  return (
    <div className="project-content" style={{ width: 3662 }}>
      <div className="screen-landing">
        <div className="screen-landing-title">RequestButton</div>
        <div className="owned">
          Privately Owned & <br /> Under Development
        </div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Design & Developmenst</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>Nigeria</h2>
          </div>
          {location.pathname.split("/").includes("bamise") && (
            <div className="location-work timeline">
              <p>TIMELINE</p>
              <h2>On-going</h2>
            </div>
          )}
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1512 }}>
        <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        />
      </div>
      <div className="owned-img-container" style={{ width: 1512 }}>
        <img
          src="/images/MacBook Air (2022).png"
          alt=""
          className="owned-img"
        />
      </div>
    </div>
  );
};

export default RequestButton;
