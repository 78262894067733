import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import AboutPage from "./pages/AboutPage";
import Work from "./pages/Work";
import { AnimatePresence } from "framer-motion";
import ContactUs from "./pages/ContactUs";
import AboutMobilePage from "./pages/AboutMobilePage";
import WorkProjectPage from "./pages/WorkProjectPage";
import PageNotFound from "./pages/PageNotFound";
import { useEffect, useState } from "react";
import WorkProjectScreen from "./pages/WorkProjectScreen";
import SafulPayPage from "./pages/SafulPayPage";
import AteniPage from "./pages/AteniPage";
import EndoPage from "./pages/EndoPage";
import GetLinkedPage from "./pages/GetLinkedPage";
import ImmunocorePage from "./pages/ImmuncorePage";
import LendSqrPage from "./pages/LendsqrPage";
import RequestButtonPage from "./pages/RequestButtonPage";
import TriggerPage from "./pages/TriggerPage";
import ScrollToTop from "./ScrollToTheTop";
import SmoothScroll from "smooth-scroll";
import ProjectFlex from "./pages/ProjectFlex";
import FloorPlan from "./pages/Demo";
import AboutBamise from "./bamise/pages/AboutBamise";
import ContactBamise from "./bamise/pages/ContactBamise";
import WorkBamise from "./bamise/pages/WorkBamise";
import ProjectFlexBamise from "./bamise/pages/ProjectFlexBamise";
import JarvisPage from "./pages/JarvisPage";
import ThoughtProcessPage from "./bamise/pages/ThoughtProcessPage";

function App() {
  const location = useLocation();
  const [isHomeLink, setIsHomeLink] = useState(false);
  const [backExist, setBackExist] = useState(false);
  const [individualDetails, setIndividualDetails] = useState({
    name: "",
    organisation: "",
    buisnessAbout: "",
    contactDetails: "",
  });

  useEffect(() => {
    // Initialize SmoothScroll for anchor links
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 300, // Adjust the speed of the scroll
      speedAsDuration: true, // Use the speed as the duration
    });

    // Optional: Clean up the effect on component unmount
    return () => scroll.destroy();
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            index
            exact
            element={
              <Home
                backExist={backExist}
                setBackExist={setBackExist}
                setIsHomeLink={setIsHomeLink}
                isHomeLink={isHomeLink}
                individualDetails={individualDetails}
                setIndividualDetails={setIndividualDetails}
              />
            }
          />
          <Route
            path="/about-us"
            element={
              <About
                backExist={backExist}
                setBackExist={setBackExist}
                setIsHomeLink={setIsHomeLink}
                individualDetails={individualDetails}
              />
            }
          />
          <Route
            path="/projects"
            element={
              <Work setIsHomeLink={setIsHomeLink} isHomeLink={isHomeLink} />
            }
          />
          <Route
            path="/projects/project-flex"
            element={
              <ProjectFlex
                setIsHomeLink={setIsHomeLink}
                isHomeLink={isHomeLink}
              />
            }
          />
          <Route
            path="/work/jarvis"
            element={<JarvisPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/safulpay"
            element={<SafulPayPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/ateni"
            element={<AteniPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/endo"
            element={<EndoPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/getlinked"
            element={<GetLinkedPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/immunocore"
            element={<ImmunocorePage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/lendsqr"
            element={<LendSqrPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/requestbutton"
            element={<RequestButtonPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/work/trigger"
            element={<TriggerPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/contact-us"
            element={<ContactUs setIsHomeLink={setIsHomeLink} />}
          />
          {/* <Route path="/demo" element={<FloorPlan />} /> */}
          <Route
            path="*"
            element={<PageNotFound setIsHomeLink={setIsHomeLink} />}
          />

          {/* Bamise */}
          <Route
            path="/bamise"
            element={
              <AboutBamise
                backExist={backExist}
                setBackExist={setBackExist}
                setIsHomeLink={setIsHomeLink}
                individualDetails={individualDetails}
              />
            }
          />
          <Route
            path="/bamise/contact-bamise"
            element={<ContactBamise setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/projects"
            element={
              <WorkBamise
                setIsHomeLink={setIsHomeLink}
                isHomeLink={isHomeLink}
              />
            }
          />
          <Route
            path="/bamise/projects/project-flex"
            element={
              <ProjectFlexBamise
                setIsHomeLink={setIsHomeLink}
                isHomeLink={isHomeLink}
              />
            }
          />
          <Route
            path="/bamise/work/jarvis"
            element={<JarvisPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/safulpay"
            element={<SafulPayPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/ateni"
            element={<AteniPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/endo"
            element={<EndoPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/getlinked"
            element={<GetLinkedPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/immunocore"
            element={<ImmunocorePage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/lendsqr"
            element={<LendSqrPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/requestbutton"
            element={<RequestButtonPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/work/trigger"
            element={<TriggerPage setIsHomeLink={setIsHomeLink} />}
          />
          <Route
            path="/bamise/thought-process"
            element={<ThoughtProcessPage setIsHomeLink={setIsHomeLink} />}
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
