import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import BurgerMenu from "../components/BurgerMenu";
import { AnimatePresence, motion } from "framer-motion";
import MenuContainer from "../components/MenuContainer";
import StickyCursor from "../components/StickyCursor";
import PageTransition from "../components/PageTransition";
import "../css/page-not-found.css";
import { Link, useLocation } from "react-router-dom";
import BtnMagnetic from "../components/ButtonMagnetic";
import GrabMagnetic from "../components/GrabMagnetic";
import MenuMobileContainer from "../components/MenuMobileContainer";
import { Helmet } from "react-helmet";

const PageNotFound = ({ setIsHomeLink }) => {
  const [menuBool, setMenuBool] = useState(false);
  const stickyElement = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [menuRepoBool, setMenuRepoBool] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="page-not-found">
          <Helmet>
            <title>Blueprint - 404 | Lost in the Digital World?</title>
          </Helmet>
          <Navbar ref={stickyElement} currentIndex={2} />
          <AnimatePresence mode="wait">
            {menuBool && (
              <MenuContainer
                error={true}
                setMenuBool={setMenuBool}
                setIsHomeLink={setIsHomeLink}
              />
            )}
          </AnimatePresence>
          <BurgerMenu
            ref={stickyElement}
            menuBool={menuBool}
            setMenuBool={setMenuBool}
            currentIndex={2}
          />
          <div className="error-container">
            <div className="left-error">
              <h1>
                Error 404 <br />
                Are you lost?
              </h1>
              {location.pathname.split("/").includes("bamise") ? (
                <div className="error-footer">
                  <Link to="/bamise" className="link">
                    <div className="left">
                      <h3>About</h3>
                      <p>
                        Learn about how BluePrint <br />
                        brings your ideas to reality
                      </p>
                    </div>
                    <div className="right">
                      <GrabMagnetic>
                        <div className="icon-container">
                          <img src="/svgs/angle-right.svg" alt="" />
                        </div>
                      </GrabMagnetic>
                    </div>
                  </Link>
                  <Link to="/bamise/contact-bamise" className="link">
                    <div className="left">
                      <h3>Contact</h3>
                      <p>
                        Ready to bring your <br /> ideas to reality?
                      </p>
                    </div>
                    <div className="right">
                      <GrabMagnetic>
                        <div className="icon-container">
                          <img src="/svgs/angle-right.svg" alt="" />
                        </div>
                      </GrabMagnetic>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="error-footer">
                  <Link to="/" className="link">
                    <div className="left">
                      <h3>Get Started</h3>
                      <p>
                        See things from <br /> the beginning
                      </p>
                    </div>
                    <div className="right">
                      <GrabMagnetic>
                        <div className="icon-container">
                          <img src="/svgs/angle-right.svg" alt="" />
                        </div>
                      </GrabMagnetic>
                    </div>
                  </Link>
                  <Link to="/about-us" className="link">
                    <div className="left">
                      <h3>About Us</h3>
                      <p>
                        Learn about how BluePrint <br />
                        brings your ideas to reality
                      </p>
                    </div>
                    <div className="right">
                      <GrabMagnetic>
                        <div className="icon-container">
                          <img src="/svgs/angle-right.svg" alt="" />
                        </div>
                      </GrabMagnetic>
                    </div>
                  </Link>
                  <Link to="/contact-us" className="link">
                    <div className="left">
                      <h3>Contact Us</h3>
                      <p>
                        Ready to bring your <br /> ideas to reality?
                      </p>
                    </div>
                    <div className="right">
                      <GrabMagnetic>
                        <div className="icon-container">
                          <img src="/svgs/angle-right.svg" alt="" />
                        </div>
                      </GrabMagnetic>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div className="right-error">
              {location.pathname.split("/").includes("bamise") ? (
                <p>Follow Bamise</p>
              ) : (
                <p>Follow Us</p>
              )}
              {location.pathname.split("/").includes("bamise") ? (
                <GrabMagnetic>
                  <Link
                    to="https://www.linkedin.com/in/yusuf-oluwatimileyin"
                    target="_blank"
                  >
                    <img src="/svgs/linkedin_last.svg" alt="" />
                  </Link>
                </GrabMagnetic>
              ) : (
                <GrabMagnetic>
                  <Link
                    to="https://www.linkedin.com/company/chooseblueprint"
                    target="_blank"
                  >
                    <img src="/svgs/linkedin_last.svg" alt="" />
                  </Link>
                </GrabMagnetic>
              )}
              <GrabMagnetic>
                <Link>
                  <img src="/svgs/instagram_last.svg" alt="" />
                </Link>
              </GrabMagnetic>
            </div>
          </div>
          <StickyCursor currentIndex={2} stickyElement={stickyElement} />
          <PageTransition pageTitle={"404"} />
        </div>
      ) : (
        <div className="page-not-found-mobile">
          <Helmet>
            <title>Blueprint - 404 | Lost in the Digital World?</title>
          </Helmet>
          <img src="/svgs/home_mob_bg.svg" alt="" className="mobile-bg" />
          <Navbar
            setIsHomeLink={setIsHomeLink}
            currentIndex={2}
            setMenuRepoBool={setMenuRepoBool}
          />
          <AnimatePresence mode="wait">
            {menuRepoBool && (
              <MenuMobileContainer
                setIsHomeLink={setIsHomeLink}
                setMenuRepoBool={setMenuRepoBool}
              />
            )}
          </AnimatePresence>
          <div className="container">
            <h1>
              Error 404
              <br /> Are you lost?
            </h1>
            {location.pathname.split("/").includes("bamise") ? (
              <div className="error-footer">
                <Link to="/bamise" className="link">
                  <div className="left">
                    <h3>About</h3>
                    <p>
                      Learn about how BluePrint <br />
                      brings your ideas to reality
                    </p>
                  </div>
                  <div className="right">
                    <GrabMagnetic>
                      <div className="icon-container">
                        <img src="/svgs/angle-right.svg" alt="" />
                      </div>
                    </GrabMagnetic>
                  </div>
                </Link>
                <Link to="/bamise/contact-bamise" className="link">
                  <div className="left">
                    <h3>Contact Us</h3>
                    <p>
                      Ready to bring your <br /> ideas to reality?
                    </p>
                  </div>
                  <div className="right">
                    <GrabMagnetic>
                      <div className="icon-container">
                        <img src="/svgs/angle-right.svg" alt="" />
                      </div>
                    </GrabMagnetic>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="error-footer">
                <Link to="/" className="link">
                  <div className="left">
                    <h3>Get Started</h3>
                    <p>
                      See things from <br /> the beginning
                    </p>
                  </div>
                  <div className="right">
                    <GrabMagnetic>
                      <div className="icon-container">
                        <img src="/svgs/angle-right.svg" alt="" />
                      </div>
                    </GrabMagnetic>
                  </div>
                </Link>
                <Link to="/about-us" className="link">
                  <div className="left">
                    <h3>About Us</h3>
                    <p>
                      Learn about how BluePrint <br />
                      brings your ideas to reality
                    </p>
                  </div>
                  <div className="right">
                    <GrabMagnetic>
                      <div className="icon-container">
                        <img src="/svgs/angle-right.svg" alt="" />
                      </div>
                    </GrabMagnetic>
                  </div>
                </Link>
                <Link to="/contact-us" className="link">
                  <div className="left">
                    <h3>Contact Us</h3>
                    <p>
                      Ready to bring your <br /> ideas to reality?
                    </p>
                  </div>
                  <div className="right">
                    <GrabMagnetic>
                      <div className="icon-container">
                        <img src="/svgs/angle-right.svg" alt="" />
                      </div>
                    </GrabMagnetic>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PageNotFound;
