import React from "react";
import { useLocation } from "react-router-dom";

const AboutMobileLanding = ({ individualDetails }) => {
  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const location = useLocation();

  return (
    <div className="about-mobile-landing">
      {location.pathname.split("/").includes("bamise") ? (
        <div className="welcome-content">
          <p>
            {individualDetails.name
              ? `Welcome, ${capitalizeFirstLetter(individualDetails.name)}!`
              : "Welcome!"}{" "}
            <br />
            <br /> I specialize in product design that bridges ideas and
            functionality, creating intuitive and impactful user experiences.
          </p>
        </div>
      ) : (
        <div className="welcome-content">
          <p>
            {individualDetails.name
              ? `Welcome to Blueprint, ${capitalizeFirstLetter(
                  individualDetails.name
                )}!`
              : "Welcome to Blueprint!"}{" "}
            <br />
            <br /> We’re thrilled to partner with you on your journey to
            success. Here’s a closer look at what we do and why so many trust us
            with their most important projects.
          </p>
        </div>
      )}
      {location.pathname.split("/").includes("bamise") ? (
        <h1>
          DESIGNING IDEA INTO FUNCTIO
          <br />
          NAL REALITY
        </h1>
      ) : (
        <h1>FROM YOUR IDEAS TO BLUEPRINT ING INTO REALITY</h1>
      )}
      {location.pathname.split("/").includes("bamise") ? (
        <div className="outro">
          <p>
            With a focus on usability, efficiency, and innovation, I craft
            designs that not only look great but also solve real problems.
            <br />
            <br /> Whether you’re building something new or refining an existing
            product, let’s create solutions that truly connect with users.
          </p>
        </div>
      ) : (
        <div className="outro">
          <p>
            At Blueprint, we believe that great design is the foundation of
            every successful product.
            <br />
            <br /> Our team of expert designers and developers collaborates
            closely with you to understand your vision and turn it into reality.
            Whether you’re a startup with a bold new idea or an SME looking to
            refine your existing offerings, Blueprint is here to help.
          </p>
        </div>
      )}
    </div>
  );
};

export default AboutMobileLanding;
