import { Link, useLocation } from "react-router-dom";

const WorkPortofolio = ({ work, setModal, setIsPortofolio, projects }) => {
  // const container = useRef(null);
  // const { scrollYProgress } = useScroll({
  //   target: container,
  //   offset: ["start start", "end end"],
  // });

  // useEffect(() => {
  //   const lenis = new Lenis();

  //   function raf(time) {
  //     lenis.raf(time);
  //     requestAnimationFrame(raf);
  //   }

  //   requestAnimationFrame(raf);
  // });

  const location = useLocation();

  return (
    <div className="work-portofolio-container work-horizontal">
      <div className="portofolio-container">
        {projects.map((project, index) => (
          <Link
            to={project.link}
            className="portofolio"
            onMouseEnter={() => {
              setModal({ active: true, index });
              setIsPortofolio(true);
            }}
            onMouseLeave={() => {
              setIsPortofolio(false);
              setModal({ active: false, index });
            }}
          >
            <h2>{project.title}</h2>
            {!location.pathname.split("/").includes("bamise") && (
              <p>{project.category}</p>
            )}
            <p>{project.location}</p>
            <p>{project.year}</p>
          </Link>
        ))}
      </div>
      {/* <div className="portofolio-horizontal-container">
        {projects.map((project, i) => {
          const targetScale = 1 - (projects.length - i) * 0.05;

          return (
            <Card
              key={`p_${i}`}
              i={i}
              {...project}
              progress={scrollYProgress}
              range={[i * 0.25, 1]}
              targetScale={targetScale}
            />
          );
        })}
      </div> */}
    </div>
  );
};

export default WorkPortofolio;
