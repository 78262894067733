import React from "react";
import "../../css/work/project.content.css";
import { useLocation } from "react-router-dom";

const SafulPay = () => {
  const location = useLocation();

  return (
    <div className="project-content" style={{ width: 8500 }}>
      {/* {title} */}
      <div className="screen-landing">
        <div className="screen-landing-title">SafulPay</div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Branding & Product Design</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>Sierra Lone</h2>
          </div>
          {location.pathname.split("/").includes("bamise") && (
            <div className="location-work timeline">
              <p>TIMELINE</p>
              <h2>
                2 months/
                <br /> 500+ screens
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className="project-full-image">
        <img
          src="/images/safulpay_longimage.png"
          alt=""
          className="project-image"
          style={{ width: 5100 }}
          loading="lazy"
        />
      </div>
      <div className="laptop-image-container">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/safulpay_desktop.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="phones">
        <img
          src="/images/safulpay_potrait_1.png"
          loading="lazy"
          className="mobile"
          alt=""
        />
        <img
          src="/images/safulpay_potrait_2.png"
          loading="lazy"
          className="mobile"
          alt=""
        />
      </div>
    </div>
  );
};

export default SafulPay;
