import React from "react";
import GrabMagnetic from "../../components/GrabMagnetic";
import BtnProceed from "../../components/BtnProceed";
import "../../css/about/about.css";

const AboutBamiseLanding = ({ scrollToEnd, individualDetails }) => {
  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className="about-landing" style={{ paddingRight: "150px" }}>
      <div className="plan">
        <h1>DESIGNING IDEAS</h1>
      </div>
      <div className="plan">
        <p className="welcome" style={{ fontSize: "23px" }}>
          {individualDetails.name
            ? `Welcome to Blueprint, ${capitalizeFirstLetter(
                individualDetails.name
              )}!`
            : "Welcome to Blueprint!"}{" "}
          <br /> <br />I specialize in product design that bridges ideas and
          functionality, creating intuitive and impactful user experiences.
        </p>
        <h1 className="slim">INTO FUNCTIONAL</h1>
      </div>
      <div className="plan">
        <h1>REALITY.</h1>
        <div className="container">
          <p style={{ fontSize: "22px" }} className="bamise">
            With a focus on usability, efficiency, and innovation, I craft
            designs that not only look great but also solve real problems.
            <br /> <br /> Whether you’re building something new or refining an
            existing product, let’s create solutions that truly connect with
            users.
          </p>
          <GrabMagnetic>
            <BtnProceed
              handleNext={scrollToEnd}
              // isFilled={isFilled}
              // isFilled2={isFilled2}
              // handleNext={handleNext}
            />
          </GrabMagnetic>
        </div>
      </div>
    </div>
  );
};

export default AboutBamiseLanding;
